exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agencia-partner-bigcommerce-js": () => import("./../../../src/pages/agencia-partner-bigcommerce.js" /* webpackChunkName: "component---src-pages-agencia-partner-bigcommerce-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-casos-de-exito-js": () => import("./../../../src/pages/casos-de-exito.js" /* webpackChunkName: "component---src-pages-casos-de-exito-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketplace-ecommerce-js": () => import("./../../../src/pages/marketplace-ecommerce.js" /* webpackChunkName: "component---src-pages-marketplace-ecommerce-js" */),
  "component---src-pages-migracion-magento-2-js": () => import("./../../../src/pages/migracion-magento-2.js" /* webpackChunkName: "component---src-pages-migracion-magento-2-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-proyectos-magento-ecommerce-js": () => import("./../../../src/pages/proyectos-magento-ecommerce.js" /* webpackChunkName: "component---src-pages-proyectos-magento-ecommerce-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-soluciones-ecommerce-b-2-b-js": () => import("./../../../src/pages/soluciones-ecommerce-b2b.js" /* webpackChunkName: "component---src-pages-soluciones-ecommerce-b-2-b-js" */),
  "component---src-pages-soluciones-ecommerce-b-2-c-js": () => import("./../../../src/pages/soluciones-ecommerce-b2c.js" /* webpackChunkName: "component---src-pages-soluciones-ecommerce-b-2-c-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

